import React, { useState } from 'react'
import { Query } from 'react-apollo'
import { Button, Input, TextArea } from '../../common'

import { GET_TOPIC } from './queries'

// TODO we need to make this a mutation 
const Topic = (props) =>  {
    const { record } = props
    
    const [ edit, setEdit ] = useState(false)
    const [ name, setName ] = useState(record.name)
    const [ description, setDescription ] = useState(record.description)
    
    const cancel = () => { 
        setEdit(false)
        setName(record.name)
        setDescription(record.description)
    }
    
    return  <div key={record.id}  className="container">
                <div className="card">
                    <div className="card-body">
                    <div className="container">
                    
                    </div>
                        <h5 className="card-title row">
                            <div className={edit ? "" : "col-4"}></div>
                            <div className={edit ? "col-8" : "col-4"}>
                                <Input edit={edit} value={name} set={setName}></Input>
                                
                            
                            </div>
                            <div className="col-4 ">
                                <Button
                                        type="outline-warning" 
                                        classes="float-right" 
                                        size="sm" 
                                        icon="edit" 
                                        caption="Edit"
                                        hide={edit}
                                        onClick={() => setEdit(true)}>
                                </Button>
                                
                                <Button
                                        type="outline-success" 
                                        classes="float-right" 
                                        size="sm" 
                                        icon="save" 
                                        caption="Save"
                                        hide={!edit}
                                        onClick={() => setEdit(false)}>
                                </Button>
                                <span className="float-right">&nbsp;</span>
                                <Button
                                        type="outline-secondary" 
                                        classes="float-right" 
                                        size="sm" 
                                        icon="ban" 
                                        caption="Cancel"
                                        hide={!edit}
                                        onClick={cancel}>
                                </Button>
                            </div>
                        </h5>
                        
                        <h6 className="card-subtitle mb-2 text-muted">
                            <TextArea edit={edit} value={description} set={setDescription}></TextArea>
                        </h6>
                        <p className="card-text"></p>
                    </div>
                </div>
            </div> 
}
                        


export default (props) =>  {
    const { params } = props.match
    const id = parseInt(params.id)
    return  <Query query={GET_TOPIC} variables={{ id }}>
                    {({ loading, error, data }) => {
                    if (loading) return 'Loading...'
                    if (error) return `Error! ${error.message} + ${data}`
                    return <div className="row"><Topic record={data.topic}></Topic></div>
                    }}
            </Query>  
} 

