import React from 'react'

import { Query } from '../../helpers'
import { Link } from 'react-router-dom'
import { GET_TOPICS } from './queries'

const Topics = ({records}) => records.map( 
            (record) => <div key={record.id}  className="col-lg-3 col-md-4 col-sm-6" style={{paddingBottom: "1.5em"}}>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{record.name}</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">{record.description}</h6>
                                    <p className="card-text">lorem ipsum</p>
                                    <Link to={`topic/${record.id}`} className="card-link">Open</Link>
                                </div>
                            </div>
                        </div> 
)

const Main = ({data}) =>    <div className="row">
                                <Topics records={data.topics}></Topics>
                            </div>

export default () =>    <Query query={GET_TOPICS}>
                               <Main></Main>
                        </Query>  


