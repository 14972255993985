import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './helpers/icons'
import App from './App'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'

import { GRAPHQL_ENDPOINT, LOCAL_ENDPOINT } from './config'

import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'
import { getToken } from './helpers/security'
import * as serviceWorker from './serviceWorker'


const httpLink = new HttpLink({
    uri: GRAPHQL_ENDPOINT,
    fetchOptions: {
        method: "POST"
    }
})


const authLink = setContext((_, { headers }) => 
    ({
        headers: {
        ...headers,
        'Authorization': getToken(),
        }
    })
)

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
})



const Root = () =>  <ApolloProvider client={client}>
                            <App />
                    </ApolloProvider>

ReactDOM.render(<Root></Root>, document.getElementById('root'))

serviceWorker.unregister()







