import React, { useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { Link } from 'react-router-dom'

export const Input = (props) => { 
    if(props.edit){
        return <input 
                    className={`form-control ${props.className}`}
                    onChange={e => props.set(e.target.value) }
                    value={props.value}></input>
    }else {
        return <span>{props.value}</span>
    }
}

export const TextArea = (props) => { 
    if(props.edit){
        return <textarea 
                    className={`form-control ${props.className}`}
                    onChange={e => props.set(e.target.value)}
                    value={props.value}></textarea>
    }else {
        return <span>{props.value}</span>
    }
}

export const Button = (props) => { 
    const style = {}
    if(props.hide){
        return <div></div>
    }
    else {
        if(props.icon){
            return  <button style={style} 
                            onClick={props.onClick} 
                            className={`btn btn-${props.type || "primary"} btn-${props.size || "md"} ${props.classes}`}>
                        <span>{props.caption }&nbsp;<Icon icon={props.icon}></Icon>&nbsp;</span>
                    </button>
        }
        else {
            return  <button style={style} 
                            onClick={props.onClick} 
                            className={`btn btn-${props.type || "primary"} btn-${props.size || "md"} ${props.classes}`}>
                        &nbsp;{props.caption || ""}&nbsp;
                    </button>
        }
        
    }
    
} 



export const Links = () => <ul className="nav flex-column">
                <li className="nav-item">
                  <Link className="nav-link active" to="">
                    <span data-feather="home"></span>
                    Topics <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <span data-feather="file"></span>
                    Students
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <span data-feather="shopping-cart"></span>
                    Methods
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <span data-feather="users"></span>
                    Customers
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <span data-feather="bar-chart-2"></span>
                    Reports
                  </a>
                </li>
              </ul>

