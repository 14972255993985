import React from 'react'
import { Query as ApolloQuery, Mutation as ApolloMutation } from 'react-apollo'


export const Query = (props) => {
    const { children, query, variables} = props
    return <ApolloQuery query={query} variables={variables}>
                {({ loading, error, data , refetch}) => {
                    if (loading) return 'Loading...'
                    if (error) {
                        console.error("ERROR", JSON.stringify(error, null, 4))
                        return `Error! ${error.message} + ${data}`
                    }
                    return React.cloneElement(children, {...props, data, refetch})
                }}
        </ApolloQuery>  
}
        

export const Mutation = (props) => { 
    const { children, mutation, variables, update } = props 

    return  <ApolloMutation mutation={mutation} variables={variables}
                update={update}>
                {update => React.cloneElement(children, {...props, update})}
            </ApolloMutation>

}
