import React from 'react'
import './App.css'
import cookies from './helpers/cookies'
import security, { signOut} from './helpers/security'
import Routes from './Routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { Links } from './common'

export default () => { 
  console.log("cookie!", cookies.getItem("ignei-token"))
  security()

    return (
        <Router>
          <div className="App">
            <div className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
              <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="#">Dactyl</a>
              <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" />
              <ul className="navbar-nav px-3">
                <li className="nav-item text-nowrap">
                  <button className="btn btn-link nav-link" href="#" onClick={signOut.bind(this)}>Sign out</button>
                </li>
              </ul>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-2 d-none d-md-block bg-light sidebar">
                  <div className="sidebar-sticky">
                    <Links></Links>
                  </div>
                </div>
                <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Topics</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                      <div className="btn-group mr-2" style={{display: "none"}}>
                        <button className="btn btn-sm btn-outline-secondary">Share</button>
                        <button className="btn btn-sm btn-outline-secondary">Export</button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Routes></Routes>
                  </div>
                  
                </main>
              </div>
            </div>
          </div>
        </Router>
    )
  }




