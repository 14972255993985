import gql from 'graphql-tag'

export const GET_TOPICS = gql` {
    topics {
        id
        name
        description
    } 
}`


export const GET_TOPIC = gql` 
    query Topic($id: Int) {
        topic(id: $id) {
        id
        name
        description
        }
    }
`