import cookies from './cookies'

export default () => {
    if(window.location.origin == "http://localhost:3001")
    {
        console.log("We are local!")
    }
    else 
    {
        if(cookies.getItem("ignei-token")) { 
            console.log("Secure")
        }
        else { 
            alert("you are not logged in, please log in")
            window.location = "https://auth.ignei.com"
        }
        console.log("We are live! Do security stuff")
    }
}

export const getToken = () => cookies.getItem("ignei-token")


export const signOut = () =>  { 
    var myDate = new Date()
    myDate.setMonth(myDate.getMonth() + 12)
    cookies.setItem('ignei-token', "", myDate , '/', '.ignei.com')
    alert("You are now signed out, please sign back in to access Dactyl content")
    window.location = "https://auth.ignei.com"
}